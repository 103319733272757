export const INSTANT_SEARCH_HOST = 'articles-search-new.konsolenkost-tools.de';
export const INSTANT_SEARCH_PORT = 443;
export const INSTANT_SEARCH_PROTOCOL = 'https';
export const INSTANT_SEARCH_PATH = '/typesense';
export const INSTANT_SEARCH_APP_ID = 'konsolenkost';
export const INSTANT_SEARCH_API_KEY = 'jZTCb103h1Ywxz86ASiciAyBGGewODPZ'; //'8aMJG7D0VDLQRsCgbeVqQwWOndpdaQ3c';

export const INSTANT_SEARCH_INDEX_NAME = 'item_group';
export const INSTANT_SEARCH_QUERY_SUGGESTIONS = 'item_group_query';
export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTE = 'category.lvl1';
