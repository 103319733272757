export const icons = {
  'system_manufacturer': {
    "Nintendo": {
      "icon": "/icons/manufacturer/nintendo.svg",
      "color": "#CC2131",
      "textColor": "#FFFFFF"
    },
    "Acclaim": {
      "icon": "/icons/manufacturer/acclaim.svg",
      "color": "#000000",
      "textColor": "#FFFFFF"
    },
    "Konami": {
      "icon": "/icons/manufacturer/konami.svg",
      "color": "#8F99A4",
      "textColor": "#000000"
    },
    "Atari": {
      "icon": "/icons/manufacturer/atari.svg",
      "color": "#E62128",
      "textColor": "#FFFFFF"
    },
    "Ubisoft": {
      "icon": "/icons/manufacturer/ubisoft.svg",
      "color": "#25085F",
      "textColor": "#FFFFFF"
    },
    "Activision": {
      "icon": "/icons/manufacturer/activision.svg",
      "color": "#31006B",
      "textColor": "#FFFFFF"
    },
    "Capcom": {
      "icon": "/icons/manufacturer/capcom.svg",
      "color": "#88DDFF",
      "textColor": "#000000"
    },
    "Electronic Arts GmbH": {
      "icon": "/icons/manufacturer/ea.svg",
      "color": "#FF4747",
      "textColor": "#FFFFFF"
    },
    "EIDOS GmbH": {
      "icon": "/icons/manufacturer/eidos-interactive.svg",
      "color": "#F3F3F3",
      "textColor": "#000000"
    },
    "Disney Interactive Studios": {
      "icon": "/icons/manufacturer/disney-interactive.svg",
      "color": "#FFFFFF",
      "textColor": "#000000"
    },
    "Sega": {
      "icon": "/icons/manufacturer/sega.svg",
      "color": "#0060A8",
      "textColor": "#FFFFFF"
    },
    "Sony": {
      "icon": "/icons/manufacturer/sony.svg",
      "color": "#000000",
      "textColor": "#FFFFFF"
    },
    "Panasonic": {
      "icon": "/icons/manufacturer/panasonic.svg",
      "color": "#2866AD",
      "textColor": "#FFFFFF"
    },
    "Apple": {
      "icon": "/icons/manufacturer/apple.svg",
      "color": "#FFFFFF",
      "textColor": "#000000"
    },
    "Commodore": {
      "icon": "/icons/manufacturer/commodore.svg",
      "color": "#EEF1DB",
      "textColor": "#000000"
    },
    "Microsoft": {
      "icon": "/icons/manufacturer/microsoft.svg",
      "color": "#0e7a0d",
      "textColor": "#FFFFFF"
    },
    "Philips": {
      "icon": "/icons/manufacturer/philips.svg",
      "color": "#1978BE",
      "textColor": "#FFFFFF"
    },
    "SNK": {
      "icon": "/icons/manufacturer/snk.svg",
      "color": "#0066FF",
      "textColor": "#FFFFFF"
    },
    "NEC": {
      "icon": "/icons/manufacturer/nec.svg",
      "color": "#1414A0",
      "textColor": "#FFFFFF"
    },
    "LEGO": {
      "icon": "/icons/manufacturer/lego.svg",
      "color": "#D01012",
      "textColor": "#000000"
    },
  },
  'type': {
    'Spiele': {
      "icon": "/icons/type/cd.svg",
      "color": "#000000",
      "textColor": "#FFFFFF",
      "keepText": true
    },
    'Konsolen': {
      "icon": "/icons/type/console.svg",
      "color": "#000000",
      "textColor": "#FFFFFF",
      "keepText": true
    },
    'Zubehör': {
      "icon": "/icons/type/controller.svg",
      "color": "#000000",
      "textColor": "#FFFFFF",
      "keepText": true
    },
    'Figuren': {
      "icon": "/icons/type/figurine.svg",
      "color": "#000000",
      "textColor": "#FFFFFF",
      "keepText": true
    },
  }
}
