<template>
  <div ref="autocompleteContainer"></div>
</template>

<script>
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch/vue2/es';
import { INSTANT_SEARCH_INDEX_NAME, INSTANT_SEARCH_QUERY_SUGGESTIONS } from '../constants';
import { searchClient, typesenseClient } from '../searchClient';
import { createTagsPlugin } from '@algolia/autocomplete-plugin-tags';
import { icons } from '../icons';

function debounce (fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

function mergeFacets(values, original = {}) {
  return Object.entries(values).reduce((acc, [facet, value]) => {
    acc[facet] = Array.isArray(acc[facet]) ? [...acc[facet], ...value] : value;
    return acc;
  }, original);
}

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  data () {
    return {
      autocompleteInstance: null,
      facetTags: [
        { 
          facet: 'category.lvl1',
          sourceId: 'facetTagSystem',
          label: 'System',
          searchStateKey: 'hierarchicalFacetsRefinements',
          uiStateKey: 'hierarchicalMenu',
          excludeSelected: true,
        },
        { 
          facet: 'type',
          sourceId: 'facetTagType',
          label: 'Typ',
          searchStateKey: 'disjunctiveFacetsRefinements',
          uiStateKey: 'refinementList',
          excludeSelected: true,
        },
        { 
          facet: 'system_manufacturer',
          sourceId: 'facetTagSystemManufacturer',
          label: 'Hersteller',
          searchStateKey: 'disjunctiveFacetsRefinements',
          uiStateKey: 'refinementList',
          badge: true,
        },
      ]
    };
  },
  methods: {
    mapToAlgoliaFilters (tagsByFacet, operator = '&&') {
      return Object.keys(tagsByFacet)
        .map((facet) => {
          return `(${tagsByFacet[facet]
            .map(({ value }) => `${facet}:"${value}"`)
            .join(' || ')})`;
        })
        .join(` ${operator} `);
    },
    groupBy (items, predicate) {
      return items.reduce((acc, item) => {
        const key = predicate(item);

        if (!acc.hasOwnProperty(key)) {
          acc[key] = [];
        }

        acc[key].push(item);

        return acc;
      }, {});
    },
    setInstantSearchUiState ({ query, menu, toggle, range }) {
      this.instantSearchInstance.setUiState((uiState) => {
        const { ...facets } = { ...uiState[INSTANT_SEARCH_INDEX_NAME] };

        if (menu) facets.menu = menu;
        if (toggle) facets.toggle = { ...facets.toggle, ...toggle };
        if (range) facets.range = { ...facets.range, ...range };

        return ({
          ...uiState,
          [INSTANT_SEARCH_INDEX_NAME]: {
            ...uiState[INSTANT_SEARCH_INDEX_NAME],
            ...facets,
            page: 1,
            query,
          },
        })
      });
    },
    addRefinement(type, facet, value, reset = false) {
      this.instantSearchInstance.setUiState((uiState) => {
        const originalQuery = uiState[INSTANT_SEARCH_INDEX_NAME].query ?? '';
        const originalType = uiState[INSTANT_SEARCH_INDEX_NAME][type] ?? {};
        const originalFacet = originalType[facet] ?? []

        return ({
          ...uiState,
          [INSTANT_SEARCH_INDEX_NAME]: {
            ...uiState[INSTANT_SEARCH_INDEX_NAME],
            page: 1,
            query: reset ? '' : originalQuery,
            [type]: {
              ...originalType,
              [facet]: [...originalFacet, value],
            },
          },
        });
      });
    },
    removeRefinement(type, facet, value, reset = false) {
      this.instantSearchInstance.setUiState((uiState) => {
        const originalQuery = uiState[INSTANT_SEARCH_INDEX_NAME].query ?? '';
        const originalType = uiState[INSTANT_SEARCH_INDEX_NAME][type] ?? {};
        const originalFacet = originalType[facet] ?? []
        const newFacet = originalFacet.filter((v) => v !== value);

        return {
          ...uiState,
          [INSTANT_SEARCH_INDEX_NAME]: {
            ...uiState[INSTANT_SEARCH_INDEX_NAME],
            page: 1,
            query: reset ? '' : originalQuery,
            [type]: {
              ...originalType,
              [facet]: newFacet.length ? newFacet : undefined,
            },
          },
        }
      });
    },
    updateTags(tagsPlugin) {
      const tags = this.facetTags.reduce((tags, { facet, searchStateKey, uiStateKey }) => {
        const refinements = this.instantSearchInstance.mainIndex.getHelper()?.state?.[searchStateKey]?.[facet] ?? [];
        return [...tags, ...refinements.map((value) => ({ value, facet, uiStateKey }))];
      }, []);

      tagsPlugin.data.setTags(tags);
    },
  },
  mounted () {
    const {
      instantSearchInstance,
      facetTags,
      mapToAlgoliaFilters,
      groupBy,
      setInstantSearchUiState,
      addRefinement,
      removeRefinement,
    } = this;

    const debouncedInstantSearchUiState = debounce(setInstantSearchUiState, 300);

    // Create the Autocomplete instance
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'recentSearchesPlugin',
      limit: 3,
      transformSource ({ source }) {
        return {
          ...source,
          onSelect ({ item }) {
            setInstantSearchUiState({ query: item.label });
          },
        };
      },
    });

    // Create the Query Suggestions plugin
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
      getSearchParams ({ state }) {
        // This creates a shared `hitsPerPage` value once the duplicates
        // between recent searches and Query Suggestions are removed
        return recentSearchesPlugin.data.getAlgoliaSearchParams({
          query: state.query,
          hitsPerPage: 6,
        });
      },
      transformSource ({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect ({ item }) {
            setInstantSearchUiState({ query: item.query });
          },
          getItems (params) {
            // We don't display Query Suggestions when there's no query
            if (!params.state.query) {
              return [];
            }
            const { transformResponse, ...result } = source.getItems(params);
            result.transformResponse = (response) => {
              return transformResponse(response).map(({ q, count }) => ({
                query: q,
                popularity: count
              }));
            };

            return result;
          },
        };
      },
    });

    const tagsPlugin = createTagsPlugin({
      getTagsSubscribers () {
        return facetTags.map(({ sourceId }) => ({
          sourceId,
          getTag ({ item }) {
            return item
          },
        }));
      },
      transformSource ({ source }) {
        return {
          ...source,
          templates: {
            ...source.templates,
            item ({ item, html }) {
              return html`<div className="aa-Tag">
                <span className="aa-TagLabel">${item.value}</span>
                <button
                  className="aa-TagRemoveButton"
                  title="Remove this tag"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="16px"
                    height="16px"
                  >
                    <path d="M18 6L6 18"></path>
                    <path d="M6 6L18 18"></path>
                  </svg>
                </button>
              </div>`;
            },
          },
        };
      },
    });

    const initialState = instantSearchInstance.mainIndex.getHelper()?.state || {};

    this.autocompleteInstance = autocomplete({
      container: this.$refs.autocompleteContainer,
      placeholder: 'Search for products',
      detachedMediaQuery: 'none',
      initialState: { query: initialState.query || '' },
      openOnFocus: true,
      plugins: [tagsPlugin, recentSearchesPlugin, querySuggestionsPlugin],
      async getSources ({ query, state }) {
        const tagsByFacet = groupBy(
          state.context.tagsPlugin.tags,
          (tag) => tag.facet
        );

        const typesenseFacets = await typesenseClient.collections(INSTANT_SEARCH_INDEX_NAME).documents().search({
          q: query,
          query_by: ['*'],
          facet_by: facetTags.map(({ facet }) => facet),
          filter_by: mapToAlgoliaFilters(tagsByFacet),
          max_facet_values: 5,
          limit: 1
        })

        return [
          ...facetTags.map(({ sourceId, facet, label, uiStateKey, excludeSelected, badge }, i) => ({
            sourceId,
            transformResponse ({ facetHits }) {
              return facetHits[0].map((hit) => ({ ...hit, facet, uiStateKey }));
            },
            templates: {
              header ({ html }) {
                return html`<div>
                  <span class="aa-SourceHeaderTitle">${label}</span>
                  <div class="aa-SourceHeaderLine"></div>
                </div>`;
              },
              item ({ item, html }) {
                const facetIcons = icons[item.facet] ?? {};
                const { icon, color = "#000000", textColor = "#FFFFFF", keepText } = facetIcons[item.value] ?? {};
                const style = badge ? `
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  height: 1.75rem;
                  padding: .3rem 0.5rem;
                  gap: .5rem;
                  color: ${textColor};
                  background-color: ${color};
                ` : `display: flex;`;

                return icon && badge
                  ? html`<div style="${style}" title="${item.value}">
                      <img src="${icon}" style="object-fit: contain; width: 100%; height: 100%" />
                      ${keepText ? item.value : ''}
                    </div>`
                  : html`<div style="${style}" >
                      ${item.value}
                    </div>`;
              },
              noResults ({ html }) {
                return html`<svg
                    width="36.055744"
                    height="27.044922"
                    viewBox="0 0 41.02895 23.766749"
                    style="fill:#888"
                  >
                      <path
                        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z"
                      />
                      <path
                        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z"
                      />
                  </svg>`;
              },
            },
            getItems () {
              return typesenseFacets.facet_counts[i].counts
                .filter(({ value }) => excludeSelected ? !tagsByFacet[facet]?.some((tag) => tag.value === value) : true)
                .map((hit) => ({ ...hit, facet, uiStateKey }))
            },
            onSelect ({ item, setQuery }) {
              const { value, uiStateKey } = item;

              const reference = value.toLowerCase();
              const queryWords = (query || '').split(' ');
              const filteredWords = queryWords.filter((word) => !reference.includes(word.toLowerCase()));
              const query = filteredWords.join(' ');

              addRefinement(uiStateKey, facet, value);
              if (filteredWords.length < queryWords.length) {
                setQuery(query);
              }
            },
          })),
          {
            sourceId: 'products',
            templates: {
              header ({ html }) {
                return html`<div>
                  <span class="aa-SourceHeaderTitle">Produkte</span>
                  <div class="aa-SourceHeaderLine"></div>
                </div>`;
              },
              item ({ item, html, components }) {
                return html`<div style="display: flex; gap: 1rem; align-items: center;">
                  <img src="${item.cover_thumbnail}" width="72px" height="72px" style="object-fit: contain;" />
                  <div style="flex-grow: 1;">
                    <b style="display: block; margin-bottom: 0.5rem">
                      ${components.Highlight({ hit: item, attribute: 'name', tagName: 'em' })}
                    </b>
                    <div>
                      ${item.availability}
                    </div>
                    <div>
                      ab ${item.min_price.toFixed(2).replace('.', ',')} €
                    </div>
                  </div>
                </div>`;
              },
              noResults ({ html }) {
                return html`<svg
                    width="36.055744"
                    height="27.044922"
                    viewBox="0 0 41.02895 23.766749"
                    style="fill:#888"
                  >
                      <path
                        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z"
                      />
                      <path
                        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z"
                      />
                  </svg> Keine Ergebnisse gefunden`;
              },
            },
            getItems () {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX_NAME,
                    params: {
                      query,
                      filters: mapToAlgoliaFilters(tagsByFacet),
                      hitsPerPage: 5,
                    },
                  },
                ],
              });
            },
          },
          {
            sourceId: 'more',
            templates: {
              item ({ item, html }) {
                return html`<div style="display: flex; justify-content: center; align-items: center; height: 40px;">
                  ${item.value}
                </div>`;
              }
            },
            getItems () {
              return [{
                value: "Mehr..."
              }];
            }
          }
        ]
      },
      render ({ elements, render, html, state, createElement }, root) {
        const {
          recentSearchesPlugin,
          querySuggestionsPlugin,
          facetTagSystemManufacturer,
          facetTagSystem,
          facetTagType,
          products,
          more,
        } = elements;
        const { tagsPlugin } = state.context;

        render(
          html`<div class="aa-PanelLayout aa-Panel--scrollable">
            <div class="aa-PanelSections">
              <div class="aa-PanelSection--left">
                ${recentSearchesPlugin}
                ${querySuggestionsPlugin}
                ${facetTagSystemManufacturer}
                ${facetTagSystem}
                ${facetTagType}
              </div>
              <div class="aa-PanelSection--right">
                <section class="aa-Source" data-autocomplete-source-id="tagsPlugin">
                  <ul class="aa-List" role="listbox" id="autocomplete-0-tagsPlugin-list">
                    ${tagsPlugin.tags.map(
                      ({ value, facet, uiStateKey, remove }, i) => html`<li class="aa-Item" id="autocomplete-0-tagsPlugin-item-${i}" role="option" aria-selected="false">
                        <div className="aa-Tag">
                          <span className="aa-TagLabel">${value}</span>
                            ${createElement(
                              'button',
                              {
                                className: 'aa-TagRemoveButton',
                                title: 'Remove this tag',
                                onClick () {
                                  remove();
                                  removeRefinement(uiStateKey, facet, value);
                                },
                              },
                  html`<svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                width="16px"
                                height="16px"
                              >
                                <path d="M18 6L6 18"></path>
                                <path d="M6 6L18 18"></path>
                              </svg>`
                          )}
                        </div>
                      </li>`
                      )
                    }
                  </ul>
                </section>
                ${products}
                ${more}
              </div>
            </div>
          </div>`,
          root
        );
      },
      onSubmit ({ state }) {
        setInstantSearchUiState({ query: state.query });
      },
      onReset () {
        setInstantSearchUiState({ query: '' });
      },
      onStateChange ({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedInstantSearchUiState({ query: state.query });
        }
      },
    });

    instantSearchInstance.on('render', () => {
      this.updateTags(tagsPlugin);
    });
  },
  beforeDestroy () {
    this.autocompleteInstance?.destroy();
  },
  watch: {
    'instantSearchInstance.renderState': {
      immediate: true,
      handler () {
        const state = this.instantSearchInstance.mainIndex.getHelper()?.state || {};
        this.autocompleteInstance?.setContext({
          query: state.query,
        })
        // this.autocompleteInstance?.setContext({
        //   tagsPlugin: {
        //     tags: Object.entries(this.instantSearchInstance.mainIndex.getHelper()?.state?.facets ?? {})
        //       .flatMap(([facet, values]) => values.map((value) => ({ facet, value }))),
        //   },
        // });
      },
    },
  }
};
</script>

<style>
:root {
  --aa-spacing-factor: .5;
}

.aa-PanelLayout {
  padding: 0;
  font-size: smaller;
}

.aa-PanelSections {
  display: flex;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.aa-PanelSection--left,
.aa-PanelSection--right {
  padding: calc((16* 1* 1px) / 2);
  padding: calc(calc(16* 1* 1px) / 2);
  padding: var(--aa-spacing-half);
}

.aa-PanelSection--left {
  flex: 0 1 320px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: .5rem;
  background-color: #eee;
}

.aa-PanelSection--left .aa-SourceHeaderTitle {
  background-color: #eee;
  padding-right: 0;
}

.aa-PanelSection--right {
  flex: 1;
  padding-left: 0;
}

.aa-Source[data-autocomplete-source-id="facetTagSystem"] {
  flex: 1 1 50%;
  margin-right: 1rem;
}

.aa-Source[data-autocomplete-source-id="facetTagType"] {
  flex: 1 1;
  margin-right: 1rem;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] {
  padding-top: calc(16* 1* 1px);
  padding-top: var(--aa-spacing);
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-List,
.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-List {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-List {
  justify-content: stretch;
  gap: .3rem;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Item {
  background-color: rgba(var(--aa-selected-color-rgb), var(--aa-selected-color-alpha));
  cursor: unset;
}


.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-Item,
.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-Item,
.aa-Source[data-autocomplete-source-id="facetTagType"] .aa-Item {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  min-height: 0;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-Item {
  flex-grow: 1;
}

.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-Item,
.aa-Source[data-autocomplete-source-id="facetTagType"] .aa-Item {
  line-height: 1.5;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Item .aa-TagRemoveButton {
  height: 16px;
  cursor: pointer;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  padding: 0 .5rem;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-SourceNoResults,
.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-SourceNoResults,
.aa-Source[data-autocomplete-source-id="facetTagType"] .aa-SourceNoResults,
.aa-Source[data-autocomplete-source-id="products"] .aa-SourceNoResults {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}


@media screen and (max-width: 768px) {
  .aa-PanelSections {
    flex-direction: column;
    gap: 0;
  }

  .aa-PanelSection--left,
  .aa-PanelSection--right {
    padding: calc((16* 1* 1px) / 2);
    padding: calc(calc(16* 1* 1px) / 2);
    padding: var(--aa-spacing-half);
  }

  .aa-PanelSection--left {
    flex: 1;
  }

  .aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"],
  .aa-Source[data-autocomplete-source-id="facetTagSystem"],
  .aa-Source[data-autocomplete-source-id="facetTagType"] {
    display: none;
  }
}
</style>
